import React, { Fragment, Suspense, useEffect, useState } from "react";
import Preloader from "../elements/Preloader";
import NavbarOne from "../components/NavbarOne";

const NavbarFour = React.lazy(() => import("../components/NavbarFour"));
const BannerFour = React.lazy(() => import("../components/BannerFour"));
const SolutionTwo = React.lazy(() => import("../components/SolutionTwo"));
const TransportServiceArea = React.lazy(() =>
  import("../components/TransportServiceArea")
);
const ShipmentTwo = React.lazy(() => import("../components/ShipmentTwo"));
const LogisticsTwo = React.lazy(() => import("../components/LogisticsTwo"));
const FeatureThree = React.lazy(() => import("../components/FeatureThree"));
const TestimonialFour = React.lazy(() =>
  import("../components/TestimonialFour")
);
const CompanyOne = React.lazy(() => import("../components/CompanyOne"));
const FooterThree = React.lazy(() => import("../components/FooterThree"));
const ContactInner = React.lazy(() => import("../components/ContactInner"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));

const HomeFour = () => {

  

  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>

          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar Four */}
          <NavbarOne />

          {/* Banner Four */}
          <BannerFour />

          {/* Transport Service Area */}
          <TransportServiceArea />

          {/* Video Area One */}
          <VideoAreaOne />
          
          {/* Solution Two */}
          {/* <SolutionTwo /> */}

          {/* Feature Three */}
          {/* <FeatureThree /> */}

          {/* Logistics Two */}
          <LogisticsTwo />

          {/* Shipment Two */}
          <ShipmentTwo />

          {/* Testimonial Four */}
          <TestimonialFour />

          {/* Company One */}
          {/* <CompanyOne /> */}

          {/* Breadcrumb */}
          <ContactInner />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />

        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeFour;
